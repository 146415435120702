/*
 * CKEditor 5 (v12.4.0) content styles.
 * Generated on Mon, 21 Oct 2019 11:34:56 GMT.
 * For more information, check out https: *///ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html

 :root {
  --ck-image-style-spacing:1.5em {}
  --ck-todo-list-checkmark-size:16px {} }

/* ckeditor5-basic-styles/theme/code.css */

.ck-content {
  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    padding: .15em;
    border-radius: 2px; }

  .image {
    display: table;
    clear: both;
    text-align: center;
    margin: 1em auto;

    > {
      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 50px; }

      figcaption {
        display: table-caption;
        caption-side: bottom;
        word-break: break-word;
        color: hsl(0, 0%, 20%);
        background-color: hsl(0, 0%, 97%);
        padding: .6em;
        font-size: .75em;
        outline-offset: -1px; } }

    &.image_resized {
      max-width: 100%;
      display: block;
      box-sizing: border-box;

      img {
        width: 100%; }

      > figcaption {
        display: block; } } }

  .media {
    clear: both;
    margin: 1em 0;
    display: block;
    min-width: 15em; }

  .todo-list {
    list-style: none;

    li {
      margin-bottom: 5px;

      .todo-list {
        margin-top: 5px; } }

    .todo-list__label {
      > input {
        -webkit-appearance: none;
        display: inline-block;
        position: relative;
        width: var(--ck-todo-list-checkmark-size);
        height: var(--ck-todo-list-checkmark-size);
        vertical-align: middle;
        border: 0;
        left: -25px;
        margin-right: -15px;
        right: 0;
        margin-left: 0;

        &::before {
          display: block;
          position: absolute;
          box-sizing: border-box;
          content: '';
          width: 100%;
          height: 100%;
          border: 1px solid hsl(0, 0%, 20%);
          border-radius: 2px;
          transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background, 250ms ease-in-out border; }

        &::after {
          display: block;
          position: absolute;
          box-sizing: content-box;
          pointer-events: none;
          content: '';
          left: calc( var(--ck-todo-list-checkmark-size) / 3 );
          top: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
          width: calc( var(--ck-todo-list-checkmark-size) / 5.3 );
          height: calc( var(--ck-todo-list-checkmark-size) / 2.6 );
          border-style: solid;
          border-color: transparent;
          border-width: 0 calc( var(--ck-todo-list-checkmark-size) / 8 ) calc( var(--ck-todo-list-checkmark-size) / 8 ) 0;
          transform: rotate(45deg); }

        &[checked] {
          &::before {
            background: hsl(126, 64%, 41%);
            border-color: hsl(126, 64%, 41%); }

          &::after {
            border-color: hsl(0, 0%, 100%); } } }

      .todo-list__label__description {
        vertical-align: middle; } } }

  .table {
    margin: 1em auto;
    display: table;

    table {
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px double hsl(0, 0%, 70%);

      td {
        min-width: 2em;
        padding: .4em;
        border-color: hsl(0, 0%, 85%); }

      th {
        min-width: 2em;
        padding: .4em;
        border-color: hsl(0, 0%, 85%);
        font-weight: bold;
        background: hsl(0, 0%, 98%); } } }

  .page-break {
    position: relative;
    clear: both;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      position: absolute;
      border-bottom: 2px dashed hsl(0, 0%, 77%);
      width: 100%; } }

  .page-break__label {
    position: relative;
    z-index: 1;
    padding: .3em .6em;
    display: block;
    text-transform: uppercase;
    border: 1px solid hsl(0, 0%, 77%);
    border-radius: 2px;
    font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
    font-size: 0.75em;
    font-weight: bold;
    color: hsl(0, 0%, 20%);
    background: #fff;
    box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }

  .image-style-side, .image-style-align-left, .image-style-align-center, .image-style-align-right {
    max-width: 50%; }

  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing); }

  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing); }

  .image-style-align-center {
    margin-left: auto;
    margin-right: auto; }

  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing); }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: solid 5px hsl(0, 0%, 80%); }

  &[dir="rtl"] blockquote {
    border-left: 0;
    border-right: solid 5px hsl(0, 0%, 80%); }

  hr {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: hsl(0, 0%, 37%);
    margin: 0; } }

/* ckeditor5-image/theme/image.css */

/* ckeditor5-image/theme/image.css */

/* ckeditor5-image/theme/imagecaption.css */

/* ckeditor5-image/theme/imageresize.css */

/* ckeditor5-image/theme/imageresize.css */

/* ckeditor5-image/theme/imageresize.css */

/* ckeditor5-media-embed/theme/mediaembed.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-list/theme/todolist.css */

/* ckeditor5-table/theme/table.css */

/* ckeditor5-table/theme/table.css */

/* ckeditor5-table/theme/table.css */

/* ckeditor5-table/theme/table.css */

/* ckeditor5-page-break/theme/pagebreak.css */

/* ckeditor5-page-break/theme/pagebreak.css */

/* ckeditor5-page-break/theme/pagebreak.css */

/* ckeditor5-image/theme/imagestyle.css */

/* ckeditor5-image/theme/imagestyle.css */

/* ckeditor5-image/theme/imagestyle.css */

/* ckeditor5-image/theme/imagestyle.css */

/* ckeditor5-image/theme/imagestyle.css */

/* ckeditor5-block-quote/theme/blockquote.css */

/* ckeditor5-block-quote/theme/blockquote.css */

/* ckeditor5-horizontal-line/theme/horizontalline.css */

@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */

  .ck-content .page-break {
    padding: 0;

    &::after {
      display: none; } }

  /* ckeditor5-page-break/theme/pagebreak.css */ }
