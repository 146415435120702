html {
  box-sizing: border-box;
  overflow-x: hidden; }

*,
*:before,
*:after {
  box-sizing: inherit; }

*:focus {
  outline: none; }

body {
  overflow-x: hidden; }

ol {
  list-style: numeric; }
ul {
  list-style: square; }

.js-is-hidden {
  display: none !important; }

button,
input,
textarea {
  outline: none; }

input,
textarea {
  border-radius: 0;
  -webkit-appearance: none; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield; }

img {
  max-width: 100%;
  height: auto; }

main {
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.dark-theme {
  background: $darkgrey; }

.red {
  color: $red; }

a:focus {
  color: inherit;
  text-decoration: inherit; }
