.navigation {
  background-color: $hero-bg;
  background-color: var(--main-color);
  border: none !important;

  @include mobile-alt {
    position: fixed; }

  &--menu-open {
    position: absolute; }

  &__hamburger {
    background-color: transparent !important;
    border: none !important;
    &:hover, &:active, &:focus {
      background-color: transparent !important; }

    &.collapsed .navigation__hamburger-bar {
      transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;

      &:nth-child(2) {
        transform-origin: 0% 0%;
        transform: rotate(0deg) translate(0px, 0px); }

      &:nth-child(3) {
        opacity: 1;
        transform: rotate(0deg) scale(1, 1);
        background: #ffffff; }

      &:nth-child(4) {
        transform-origin: 0% 100%;
        transform: rotate(0deg) translate(0px, 0px); } } }


  &__hamburger-bar {
    background-color: #fff !important;
    width: 30px !important;
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #cdcdcd;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;

    opacity: 1;
    transform: rotate(45deg) translate(-1px, -2px);
    background: #232323;

    &:nth-child(1) {
      opacity: 0; }


    &:nth-child(3) {
      opacity: 0;
      transform: rotate(0deg) scale(0.2, 0.2);
      background: $hero-bg;
      background: var(--main-color); }

    &:nth-child(4) {
      transform: rotate(-45deg) translate(0, -1px); }


    &--dark {
      background-color: $darkgrey !important;
      &:nth-child(4) {
        transform: rotate(-45deg) translate(2px, -4px); } } }

  &__container {
    background-color: $hero-bg;
    background-color: var(--main-color);
    overflow: hidden;
    border: none;
    z-index: 100;

    @include mobile-alt {
      position: absolute;
      height: 100vh !important;
      width: 100vw; } }

  &__dropdown-list {
    background-color: $hero-bg;
    background-color: var(--main-color);
    border: none !important;

    @include tablet {
      background-color: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 8px !important;
      min-width: 190px; } }

  &__dropdown-list-item {
    list-style-type: none;
    padding: 4px 7px; }

  &__dropdown-list-item-link {
    color: #ffffff !important;
    @include tablet {
      color: $type-darkgrey !important;
      padding: 3px !important; }

    .navigation__dropdown-list-item-text::after {
      content: '';
      display: block;
      background-color: transparent;
      width: 0%;
      height: 3px; }

    &:hover, &:active, &:focus {
      background-color: #ffffff !important;

      .navigation__dropdown-list-item-text::after {
        content: '';
        display: block;
        background-color: $bordergrey;
        width: 100%;
        height: 3px;
        transition: width .2s ease-in; } } }

  &__list-item-link {
    color: #ffffff !important;
    font-size: 1rem;

    &::after {
      content: '';
      display: block;
      background-color: transparent;
      width: 0%;
      height: 3px;
      margin-top: 2px; }

    &:active, &:hover, &:focus, &.active {
      color: #ffffff !important;
      // font-weight: 600
      &::after {
        content: '';
        display: block;
        background-color: #ffffff;
        width: 100%;
        height: 3px;
        transition: width .2s ease-in; } }

    &--login {
      color: #ffffff !important;
      background-color: $darkgrey;
      border-radius: 8px;
      padding: 15px 17px !important;
      margin: 0 10px;
      transform: scale(1);
      text-align: center;

      &:active, &:hover, &:focus, &.active {
        color: #ffffff !important;
        background-color: $darkgrey !important;
        border-radius: 8px;
        transform: scale(1.02);
        box-shadow: $boxshadow;
        transition: .2s transform box-shadow ease-in; } } } }


