@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Thin.eot");
  src: url("../fonts/roboto/Roboto-Thin.woff") format("woff"), url("../fonts/roboto/Roboto-Thin.ttf") format("truetype"), url("../fonts/roboto/Roboto-Thin.svg#svgFontName") format("svg");
  font-weight: 100;
  font-style: normal;

  @font-face {}
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Light.eot");
  src: url("../fonts/roboto/Roboto-Light.woff") format("woff"), url("../fonts/roboto/Roboto-Light.ttf") format("truetype"), url("../fonts/roboto/Roboto-Light.svg#svgFontName") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Italic.eot");
  src: url("../fonts/roboto/Roboto-Italic.woff") format("woff"), url("../fonts/roboto/Roboto-Italic.ttf") format("truetype"), url("../fonts/roboto/Roboto-Italic.svg#svgFontName") format("svg");
  font-weight: 300;
  font-style: normal; }

// Normal Weight
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Regular.eot");
  src: url("../fonts/roboto/Roboto-Regular.woff") format("woff"), url("../fonts/roboto/Roboto-Regular.ttf") format("truetype"), url("../fonts/roboto/Roboto-Regular.svg#svgFontName") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Medium.eot");
  src: url("../fonts/roboto/Roboto-Medium.woff") format("woff"), url("../fonts/roboto/Roboto-Medium.ttf") format("truetype"), url("../fonts/roboto/Roboto-Medium.svg#svgFontName") format("svg");
  font-weight: 500;
  font-style: normal; }

// Bold Weight
@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Bold.eot");
  src: url("../fonts/roboto/Roboto-Bold.woff") format("woff"), url("../fonts/roboto/Roboto-Bold.ttf") format("truetype"), url("../fonts/roboto/Roboto-Bold.svg#svgFontName") format("svg");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../fonts/roboto/Roboto-Black.eot");
  src: url("../fonts/roboto/Roboto-Black.woff") format("woff"), url("../fonts/roboto/Roboto-Black.ttf") format("truetype"), url("../fonts/roboto/Roboto-Black.svg#svgFontName") format("svg");
  font-weight: 900;
  font-style: normal; }

/*@font-face {
 *	font-family: 'Roboto';
 *    src: url('../fonts/roboto/Roboto-Heavy2.eot');
 *	src: url('../fonts/roboto/Roboto-Heavy2.woff2') format('woff2'),
 *         url('../fonts/roboto/Roboto-Heavy2.woff') format('woff'),
 *         url('../fonts/roboto/Roboto-Heavy2.ttf')  format('truetype'),
 *         url('../fonts/roboto/Roboto-Heavy2.svg#svgFontName') format('svg');
 *	font-weight: 900;
 *	font-style: normal;
 *} */
