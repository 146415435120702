.grid-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 40px;

  &--1-col {
    grid-template-columns: repeat(auto-fill, minmax(100%, 800px));
    justify-content: center;
    place-items: center;

    .grid-container__item {
      padding: 0px 0px;

      @include mobile {
        padding: 0px 20px; } } }

  &--2-col {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
    @include mobile {
      justify-content: center;
      place-items: center; }
 }    // gap: 1rem
  &--3-col {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    grid-template-rows: auto; }

  &--4-col {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-template-rows: auto; }

  &__item {
    padding: 0px 20px;
    max-width: 100vw;

    @include tablet {
      padding: 0px 40px; }

    &--padding-tb {
      padding: 0px 20px 40px;
      @include tablet {
        // grid-row: 1
        padding: 0 20px; } }

    &--2-col-start {
      grid-column-start: 1;
      grid-column-end: 3; }

    &--bottom {
      align-self: end; }

    &--center {
      place-self: center;
      text-align: center; }

    &--full-width-flex {
      display: flex;
      justify-content: space-between;
      @include mobile {
        // grid-column: 1 / span 2
        flex-direction: column; }
      @include tablet {
        grid-column: 1 / span 3;
        flex-direction: row; }
      @include desktop {
        grid-column: 1 / span 4;
        flex-direction: row; } } }

  &__image {
    width: 100%;
    max-width: 600px;
    padding: 0 0;
    place-self: center;
    img {
      min-width: 100%; }
    &--right {
      @include tablet {
        grid-row: 1;
        grid-column: 2; } }

    &--left {
      @include tablet {
        grid-row: 1;
        grid-column: 1; } } } }
