.footer {
  padding: 40px 0 0;
  background: $darkgrey;

  &__header {
    color: #ffffff;
    margin-bottom: 6px;
    font-size: rem(22px); }

  &__list {
    padding-left: 0 !important;
    list-style: none; }

  &__link {
    color: #ffffff;
    font-size: rem(16px);
    font-weight: 200;
    &:hover, &:active, &:focus {
      color: #ffffff;
      font-weight: 400;
      text-decoration: none; }

    &--credits {
      font-size: rem(12px); } }

  &__social-links {
    display: flex;
    height: 100%;
    align-items: flex-end;
 }    // justify-content: flex-end

  &__social-links-item {
    padding: 8px;
    font-size: rem(20px);
    color: #ffffff; }

  &__company-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include mobile-only {
      flex-direction: row;
      align-items: flex-end; }
    @include tablet {
      flex-direction: row;
      align-items: flex-end; } }

  &__company-links-item {
    padding: 8px 20px 12px 0;
    img {
      width: 100px; }
    &--main {
      img {
        width: 130px; } } } }
