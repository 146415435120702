$mobile-width: 220px;
$mobile-alt-width: 425px;
$tablet-alt-width: 768px;
$tablet-width: 780px;
$tablet-max: 1024px;
$desktop-width: 1154px;
$large-width: 2048px;

//alternative just mobile breakpoint because of weird bug
@mixin mobile-only {
  @media (max-width: #{$mobile-alt-width}) {
    @content; } }

// alternative breakpoint added as a late request over the specs
@mixin mobile-alt {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-alt-width - 1px}) {
    @content; } }

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content; } }

// alternative breakpoint added as a late request over the specs
@mixin tablet-alt {
  @media (min-width: #{$tablet-alt-width}) {
    @content; } }

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content; } }

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content; } }

@mixin desktop-lg {
  @media (min-width: #{$large-width}) {
    @content; } }
