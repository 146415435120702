.caption-and-content-layout {
  padding: 80px 0;

  @include tablet {
    padding: 80px 0; }


  @include desktop {
    padding: 80px 0; }


  &__caption-container {
    margin-bottom: 25px;

    @include desktop {
      margin-bottom: 40px;

 } }      //     padding-top: 40px;

  &--fillscreen {
    min-height: calc(100vh - #{$hero-small-min-height} - 360px);
    padding-top: 50px;

    @include tablet {
      min-height: calc(100vh - #{$hero-small-min-height} - #{$footer-height-desktop});
      margin: auto; } } }

.panel-caption {
  margin: 0 20px 25px 20px;
  // text-transform: uppercase
  text-align: center;

  &__text {
    display: inline;
    // border-bottom: $thickborder
    color: $type-darkgrey;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: rem(21px);
    line-height: 1.6;
    &--subheadline {
      font-size: rem(19px);
      font-weight: 500; }

    @include desktop-lg {
      font-size: rem(24px); }


    &--dark-theme {
      color: #fff;
 } }      // border-bottom: 3px solid rgba(255, 255, 255, 0.3)

  &__checkout-step {
    display: block;

    @include tablet {
      display: none; } }

  @include desktop-lg {
    margin-bottom: 45px; } }
