.button-group {
  overflow: hidden;

  &--narrow {
    @include tablet {
      @include centeredMaxWidth(620px); }


    @include desktop {
      @include centeredMaxWidth(640px); } }

  &__btn {
    display: block;
    width: 100%;

    @include tablet {
      width: 220px; } }

  &__btn--left {
    margin: 20px auto 0px auto;
    @include tablet {
      float: left;
      margin: 0px 20px 10px 0px; } }

  &__btn--right {
    margin: 20px auto 0px auto;
    @include tablet {
      float: right;
      margin: 0px 20px 10px 0px; } }

  &__btn--center {
    margin: auto; }

  &__btn--previous {
    @include mobile {
      min-width: initial;
      width: 115px;
      margin: auto;
      order: 2;

      &,
      &:hover,
      &:active {
        background: none;
        border: none;
        color: $type-darkgrey;
        position: relative; }

      &:before {
        @include acent(v);

        height: 15px;
        width: 15px;
        content: "";

        //top: 0
        left: -0.2em;
        background-image: url(../img/icon-arrow-left.svg);
        background-position: center;
        background-repeat: no-repeat; } } }

  &__button--next {
    @include mobile {
      order: 1; } }

  @include mobile {
    display: flex;
    flex-flow: column; } }
