.messenger-strip {
  padding: 20px 0px 20px 0px;
  &__item {
    @include align-center;
    flex-direction: column;
    align-items: center;

    .image {
      margin: 0;
      max-width: 120px; }

    .text p {
      font-weight: 600;
      text-align: center; } } }
