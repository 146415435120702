/****** HELPERS ***** */

/* Grid */

/* GRID WITHOUT GUTTERS ON THE OUTSIDE
 *    ┏-------┬---┬-------┬---┬-------┓
 *    |  col  | g |  col  | g |  col  | */

// Calculates the width for a grid with our default gutter
// e.g. gridColumn(10/22) calculates the width for 10 columns in a 1/22 grid
@mixin col($ratio, $gutter: $gutter) {
  width: calc((99.99% + #{$gutter}) * #{$ratio} - #{$gutter}); }

/* mtmsGrid = 22col GRID WITH OUTSIDE GUTTERS
 * ┏---┬-------┬---┬-------┬---┬-------┬---┓
 * | g |  col  | g |  col  | g |  col  | g | */

@mixin mtmsGrid($colNum, $offsetCols: 0, $centered: false, $offset: true) {
  @if $centered == true {
    $offsetCols: (22 - $colNum) / 2;

    flex: 0 0 auto; }

  @if $offset == true {
    @include mtmsOffset($offsetCols / 22);

    width: calc((99.99% - #{$gutter}) * #{$colNum} /22 - #{$gutter}); } }

@mixin mtmsOffset($ratio) {
  margin-left: calc((99.99% - #{$gutter}) * #{$ratio} + #{$gutter}); }

/* Absolute Centering */

@mixin acent($mode: "f") {
  position: absolute;

  @if $mode == "f" {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  @else if $mode == "v" {
    top: 50%;
    transform: translate(0%, -50%); }
  @else if $mode == "h" {
    left: 50%;
    transform: translate(-50%, 0%); }
  @else if $mode == "r" {
    position: relative;
    left: auto;
    top: auto;
    transform: none; } }

/* Flex Centering */

@mixin align-center {
  display: flex;
  justify-content: center;
  align-items: center; }

/* Flex Space Between */

@mixin space-between {
  display: flex;
  justify-content: space-between; }

/* Centered Max Width */

@mixin centeredMaxWidth($maxWidth: 1440px) {
  max-width: $maxWidth;
  margin: 0 auto; }

/* Clearfix */

@mixin clearfix {
  &:before,
  &:after {
    content: "";
    display: table; }

  &:after {
    clear: both; } }

/* Rem Calc */

@function rem($size) {
  $remSize: $size / 18px;

  @return #{$remSize}rem; }

/******* ELEMENTS ****** */

/* Button */

@mixin button($bg: "cta") {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 19px 16px;
  cursor: pointer;
  font-weight: bold;
  font-size: rem(16px);
  line-height: 1;
  text-align: center;
  letter-spacing: 0.4px;
  text-decoration: none;
  text-transform: uppercase;
  font-style: normal;
  font-stretch: normal;
  transition: all 0.2s ease;

  @if $bg == "cta" {
    min-width: 220px;
    background-color: $darkgrey;
    color: #ffffff;
    border: $thickborder;
    border-color: $darkgrey;

    &:hover,
    &:active,
    &:focus {
      border: $thickborder;
      text-decoration: none;

      //background-color: #fff;
      background-color: $btn-grey;
      border-color: $btn-grey;
      color: #fff; }
    &[disabled] {
      background-color: $mediumgrey-disabled;
      color: #fff;
      border: solid 3px rgba(255, 255, 255, 0);
      cursor: not-allowed; } }
  @else if $bg == "transparent" {
    min-width: 220px;
    background-color: transparent;
    color: #ffffff;
    border: solid 3px #ffffff;

    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 1);
      color: $type-darkgrey;
      border: solid 3px rgba(51, 51, 51, 0);
      text-decoration: none;
 }      // transform: scale(1.1)
    &[disabled] {
      background-color: $mediumgrey-disabled;
      color: #fff;
      border: solid 3px rgba(255, 255, 255, 0);
      cursor: not-allowed; } }
  @else if $bg == "white" {
    min-width: 220px;
    color: $type-darkgrey;
    background-color: #ffffff;
    border: $thickborder;

    &:hover,
    &:active {
      background-color: $cyan;
      color: #ffffff;
      border: $thickborder;
      border-color: $cyan; } }
  @else if $bg == "ctrl" {
    height: 50px;
    width: 40px;
    background-color: #ffffff;
    border: $defaultborder;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(../img/icon-plus.svg);

    &:hover,
    &:active {
 } }      // background-color: $mediumgrey
  @else if $bg == "cart" {
    width: 90px;
    height: 50px;
    background-color: $btn-grey;
    border: none;
    background-image: url(../img/icon-cart.svg);
    background-size: 60% 60%;
    background-repeat: no-repeat;
    background-position: center;

    &:hover,
    &:active {
      background-color: $bordergrey; }

    @include mobile {
      width: 130px; } } }
