.form {
  @include clearfix;
  overflow: hidden;

  &__text-panel {
    clear: both;
    text-align: center; }

  &__message {
    margin-top: auto;
    font-size: rem(16px);
    text-align: center;
    line-height: 1.6;
    color: $darkgrey;

    &--boxed {
      padding: 20px;
      border: $defaultborder;
      text-align: left; }

    &--dark-theme {
      color: #ffffff;
      a {
        color: $lightgrey; } }

    @include desktop {
      font-size: 1rem;
      margin-bottom: 33px; }


    &--stay-small {
      @include desktop {
        font-size: rem(16px); } }

    &--no-margin {
      margin-bottom: 10px; } }

  &__input-field-wrapper {
    margin-bottom: 15px;

    @include clearfix;

    @include tablet {
      //      overflow: hidden;
      float: left;
      width: 300px;

      &:nth-child(odd) {
        float: right; } }


    @include desktop {
      width: 310px; }


    &--full-width {
      width: 100%;
      float: none;
      clear: both; }

    &--submit {
      float: none !important;
      margin: auto; } }

  &__input-field {
    border: $defaultborder;
    border-bottom: $defaultborder;
    border-radius: 8px;
    font-size: rem(18px);
    padding: rem(13px);
    display: inline-block;
    width: 100%;

    &--textarea {
      resize: none; }

    &--radio,
    &--checkbox {
      position: absolute;
      top: 0;
      left: -9999px;
      visibility: hidden;

      &:checked + label {
        &:before {}

        &:after {
          transform: translate3d(0, 0, 0);
          opacity: 1; } } }

    &--full-width {
      //display: inline-block;
      border: none;
      width: auto; }

    &--no-border {
      border: none; }

    &--styled {
      //  position: relative;
      padding: 0px 0px 0px 40px;
      text-align: left; }

    &--boxed {
      //  position: relative;
      padding: 20px 20px 20px 60px;
      border: $defaultborder; }

    &--centered {
      @include align-center; } }

  &__input-field-label {
    position: relative;
    margin-bottom: 10px;
    font-size: rem(13.5px);
    font-weight: 500;
    line-height: 1;
    color: #8a909a;

    span {
      display: inline-block;
      margin-left: 3px;
      color: $darkgrey; }

    &--inline {
      display: inline-block;
      margin-bottom: auto;
      vertical-align: text-top;
      color: $darkgrey;
      font-weight: 400;
      font-size: rem(16px);
      cursor: pointer;
      line-height: 1.6;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 13px; }

      &:before {
        left: -35px;
        width: 24px;
        height: 24px;
        margin: -12px 0 0;
        background: #fff;
        border: 1px solid #d0d0d0; }

      &:after {
        left: -28px;
        width: 10px;
        height: 10px;
        margin: -5px 0 0;
        opacity: 0;
        background: $darkgrey;
        transition: opacity 0.15s ease-in-out; }

      @include desktop {
        font-size: 1rem; } }

    &--stay-small {
      @include desktop {
        font-size: rem(16px); } }

    &--rounded {
      &:before,
      &:after {
        border-radius: 50%; } }

    &--dark-theme {
      color: #ffffff;

      span {
        color: #ffffff; } } }


  &__button-field-wrapper {
    clear: both;

    @include clearfix; }

  &__input-field-split {
    float: left;
    display: inline-block;

    &--narrow {
      width: 100px; }

    &--wide {
      width: calc(100% - 110px);
      margin-left: 10px;

      @include tablet {
        width: 190px; }


      @include desktop {
        width: 200px; } } }

  &__input-field-message {
    clear: both;
    float: left;
    font-size: rem(13.5px);
    line-height: 1.6;
    color: #8a909a;
    &--white {
      color: #ffffff; }
    &--centered {
      width: 100%;
      text-align: center; }

    &--dark-theme {
      color: #ffffff; } }

  &__input-group {
    display: flex;
    border-radius: 8px;
    padding-left: 35px;
    &--checkbox {
      flex-direction: column; }
    &--radio {
      flex-direction: column; } }

  &__submit {
    width: 100%;

    @include tablet {
      display: block;

      @include centeredMaxWidth(220px); } } }

.placeholder-option {
  .dropdown__text {
    color: #cdcecf; } }

.areaCode {
  .dropdown__text {
    padding: 13px 5px 13px 15px; } }

.alert-danger, .alert {
  border-radius: 8px !important; }

/* File uploader */

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    padding: 0.625rem 1.25rem;
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    overflow: hidden;

    @include tablet {
      display: inline-block;
      margin-bottom: auto; } }

  &:focus + label,
  &.has-focus + label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px; }

  & + label svg {
    vertical-align: middle;
    fill: currentColor; }

  & + label {
    color: #cdcecf;
    font-weight: 500;
    border: $defaultborder;
    padding: 0; }

  & + label span {
    width: calc(100% - 48px);
    padding: 10px 13px;
    height: 50px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;

    @include tablet {
      width: 360px; }


    @include desktop {
      width: 380px; } }

  & + label strong {
    height: 100%;
    padding: 9px 15px;
    margin-left: -7px;
    display: inline-block;
    border: 2px solid $bordergrey;
    border-left: $thickborder; }

  &__upload-button {
    width: 100%;
    min-width: initial;
    padding: 15px;
    vertical-align: top;

    @include tablet {
      width: 200px;
      float: right; } } }
