.quote {
  padding: 40px 0;
  &__container {
    background-color: $hero-bg;
    background-color: var(--main-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

  &__logo {
    max-width: 140px; }

  &__text {
    blockquote {
      font-size: rem(18px); } }

  &__author {
    color: #ffffff; } }
