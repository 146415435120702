
.hero {
	position: relative;
	// padding-top: 76px
	background: $hero-bg;
	background: var(--main-color);
	z-index: 1;

	&__container {
		display: flex;
		flex-direction: column-reverse;
		margin: 0 20px 0 20px;
		padding-bottom: 60px;

		@include tablet {
			flex-direction: row;
			justify-content: center;
			margin: 0 40px 0 40px; } }

	&__content {
		max-width: 100%;
		align-self: center;
		@include tablet {
			max-width: 640px;
			align-self: center;
			padding-top: 20px; }

		h1, h2 {
			color: #ffffff; }

		h1 {
			margin-top: 0;
			font-size: rem(26px);
			@include tablet {
				font-size: rem(32px); }

			@include desktop {
				font-size: rem(36px); } }

		h2 {
			margin-bottom: 8px;
			font-weight: 200;
			font-size: rem(22px);
			@include tablet {
				font-size: rem(26px); }

			@include desktop {
				font-size: rem(30px); } } }

	&__content-intro {
		color: #ffffff; }

	&__image {
		max-width: 100%;
		min-width: 100%;
		@include tablet {
			max-width: 50%;
			min-width: 400px;
 }			// padding-top: 60px
		@include desktop {
			max-width: 640px; } } }
