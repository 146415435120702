.image-view {
  position: relative;
  height: 100%;
  overflow: hidden;

  &__icon {
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 30px;
    z-index: 5; }

  &__strip {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    &--image {
      min-width: 0;
      width: 100%;
      padding: 0 10px; } } }


.image-view.image-view--fixed-ratio {
  padding-bottom: 66.6667%;
  height: 0; }

.image-view--preview .image-view__container {
  filter: blur(7px);
  -webkit-filter: blur(7px); }

.image-view__container {
  position: relative;
  top: -7px;
  left: -7px;
  height: calc(100% + 14px);
  width: calc(100% + 14px); }

.image-view__content {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
  object-fit: cover; }

.image-view--contain .image-view__content {
  -o-object-fit: contain;
  object-fit: contain; }

.image-view--fixed-ratio .image-view__container {
  position: absolute; }

.image-view--intrinsic .image-view__content {
  height: auto; }

.image-view--intrinsic .image-view__container {
  position: static;
  height: auto;
  width: auto; }

.image-view--intrinsic.image-view--fixed-ratio .image-view__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

.image-view--object-fit-pf .image-view__container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }

.image-view--object-fit-pf .image-view__content {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity = 0); }

.image-view--object-fit-pf.image-view--contain .image-view__container {
  background-size: contain; }
