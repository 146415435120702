@keyframes bounce {
  0% {
    transform: translateX(0%);
    opacity: 0; }

  20% {
    transform: translateX(-10%); }

  40% {
    transform: translateX(10%);
    opacity: 1; }

  60% {
    transform: translateX(-15%);
    opacity: 1; }
  70% {
    transform: translateX(20%);
    opacity: 1; }

  90% {
    transform: translateX(-5%);
    opacity: 1; }
  100% {
    transform: translateX(0%);
    opacity: 1; } }

