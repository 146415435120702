.button {
  &--cta {
    @include button("cta"); }

  &--ctrl {
    @include button("ctrl"); }

  &--cart {
    @include button("cart"); }

  &--white {
    @include button("white"); }

  &--transparent {
    @include button("transparent"); } }
