.inline {
  &-content {
    &-panel-layout {
      @include centeredMaxWidth;

      &__content {
        @include tablet {
          box-flex: 0;

          @include mtmsGrid(18, $centered: true); } }

      &--error {
        text-align: center; } }

    &-teaser-image-layout {
      @include centeredMaxWidth; }

    &__content-item {
      overflow: auto;
      margin: 0 20px;

      @include tablet {
        box-flex: 0;

        @include mtmsGrid(18, $centered: true); }


      &--narrow {
        @include tablet {
          width: 100%;

          @include centeredMaxWidth(620px); }


        @include desktop {
          @include centeredMaxWidth(640px); } }

      &--error {
        background-color: $red;
        border-color: $red;
        margin: 20px auto;
        text-align: center;
        color: #ffffff;
        padding: 10px;
        border-radius: 8px;
        animation: bounce .6s ease-in-out forwards; } } }

  &-heading {
    &--level-1 {
      margin-top: auto;
      margin-bottom: 20x;
      font-weight: bold;
      color: $type-darkgrey;
      font-size: rem(21px);
      line-height: 1.4;

      @include desktop-lg {
        font-size: rem(24px); } } }

  &-paragraph {
    margin-bottom: 20px;
    margin-top: auto;
    font-size: rem(16px);
    line-height: 1.6;
    color: $type-darkgrey;

    @include desktop-lg {
      font-size: rem(18px); }


    &__link {
      &,
      &:hover,
      &:visited {
        color: $mediumgrey;
        text-decoration: underline; } }

    &--centered {
      text-align: center; }

    &--emphasized {
      font-weight: 700; }

    &--dark-theme {
      color: #fff; } }

  &-image {
    margin: 20px auto; }

  &-quote {
    margin: auto auto 20px auto;

    &__text {
      color: $type-darkgrey;
      display: block;
      font-size: rem(24px);
      font-weight: 500;

      @include desktop-lg {
        font-size: rem(32px); } }

    &__author {
      margin-top: 8px;
      color: $mediumgrey;
      font-weight: 500;
      font-size: rem(13.5px);
      line-height: 1.4;
      display: block; } }

  &-list {
    list-style-type: square;
    margin-top: auto;
    margin-bottom: 20px;
    color: $type-darkgrey;

    &__item {
      font-size: rem(16px);
      line-height: 1.7;

      @include desktop-lg {
        font-size: rem(18px); } }

    &--dark-theme {
      color: #fff; } } }

.image-caption {
  &__title {
    margin-top: 5px;
    font-weight: 700;
    font-size: rem(12px);
    line-height: 1.4;
    color: $type-darkgrey;
    text-align: left; }

  &__copyright {
    margin-top: 4px;
    font-weight: 500;
    font-size: rem(10px);
    line-height: 1.2;
    color: $mediumgrey;
    text-transform: uppercase; } }
