//Colors
$hero-bg: #92c4d1;
$bordergrey: #95989A;
$type-darkgrey: #333333;
$lightgrey: #EDEDED;
$lightgrey-disabled: rgba(206, 206, 206, 0.7);
$mediumgrey: #868686;
$mediumgrey-disabled: rgba(134, 134, 134, 0.7);
$btn-grey: #6A6A6A;
$darkgrey: #333333;
$cyan: #499CB3;
$green: #3EEDD3;
$red: #F40808;

$cyan-type: #499CB3;

// Gradients
$red-gradient: linear-gradient(to top right, #F02020, #FB8F8F 90%, #FFB7B7 99%);
$btn-grey-gradient: linear-gradient(to top right, #5C5C5C, #A7A7A7 99%);

// Layout / Positioning
$gutter: 10px;

//Styles
$defaultborder: 1px solid $bordergrey;
$thickborder: 3px solid $bordergrey;
$boxshadow: 0px 6px 20px rgba(0, 0, 0, 0.16);

// Element Sizes
$hero-small-min-height-mobile: 214px;
$hero-small-min-height: 260px;
$hero-overview-min-height-mobile: 120px;
$hero-overview-min-height-tablet: 260px;
$footer-height-desktop: 90px;
