.iwcc-background {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    z-index: 999999;
    height: 100%;
    width: 100%;
    overflow: hidden; }

.iwcc-wrapper {
    background: #fff;
    position: relative;
    font-size: 15px;
    width: 100%;
    max-width: 60em;
    max-height: 100vh;
    border-radius: 8px;
    overflow-y: auto; }

.iwcc-wrapper-inner {
    padding: 1em;
    position: relative; }

.iwcc-hidden {
    display: none; }

.iwcc-detail {
    margin-bottom: 1em; }

.iwcc-headline {
    font-weight: bold;
    font-size: 1.2em;
    span {
        font-weight: normal; } }


.iwcc-show-details {
    padding: 0 0 1em 0;
    a {
        color: #999;
        text-decoration: none;
        cursor: pointer;
        &:hover {
            color: #333333;
            text-decoration: none; } } }



.iwcc-save-selection,
.iwcc-accept-all {
    // transition: 200ms ease all
    // background: green
    // border: 1px solid green
    // color: #fff
    // padding: .5em 2em
    // border-radius: .2em
    // font-weight: bold
    // text-align: center
    // margin: .5em 0
    // display: block
    // &:hover
    //     background: #004000
    //     border-color: #004000
    //     color: #fff
 }    //     text-decoration: none


.iwcc-save-selection {
    background: none transparent;
    border: 3px solid $mediumgrey !important;
    color: $darkgrey !important;
    // &:hover
    //     background: #666
    //     border-color: #666
 }    //     color: #fff


.iwcc-sitelinks {
    a {
        display: inline-block;
        margin: .5em .5em .5em 0;
        color: #999;
        text-decoration: none;
        &:hover {
            color: #404040;
            text-decoration: none; } } }



.iwcc-save-and-close {
    color: green;
    &:hover {
        color: #004000; } }


.iwcc-close-box {
    position: absolute;
    right: .5em;
    top: .5em;
    display: block;
    padding: 0;
    margin: 0;
    border: 0;
    cursor: pointer;
    color: #999;
    font-size: 1.8em;
    background: none transparent;
    line-height: 1;
    text-decoration: none;
    &:before {
        margin: 0; }

    &:hover {
        color: #404040;
        background: none transparent;
        text-decoration: none; } }


.iwcc-cookiegroups {
    padding: 1em 0; }

.iwcc-cookiegroup-checkbox {
    display: block;
    margin: 0 0 1em 0;
    &.pretty .state label {
        font-weight: bold;
        font-size: 1.2em;
        line-height: 1; }

    &.pretty.p-default input:checked ~ .state label::before {
        border-color: $mediumgrey; }

    &.pretty.p-default input:checked ~ .state label::after {
        background-color: $mediumgrey !important; }

    &.pretty.p-locked {
        opacity: .75; } }


.iwcc-cookiegroup {
    & + .iwcc-cookiegroup-title {
        margin-top: 1em; } }


.iwcc-cookiegroup-title {}


.iwcc-cookie {
    margin-top: .5em;
    border-left: 2px solid #999;
    padding: .5em .5em .5em 1em;
    background: #f9f9f9;
    span {
        display: block; } }


.iwcc-buttons {}

.pretty.p-icon .state .icon {
    font-size: 1.15em;
    transition: all .1s ease; }

.pretty.p-icon .state label:before {
    margin-top: -.1em; }

@media (min-width: 35em) {
    .iwcc-cookiegroups {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding-top: 2em; }

    .iwcc-cookiegroup-checkbox {
        margin: 0 2em 0 0; }

    .iwcc-show-details {
        text-align: right;
        padding: 1em 2em 2em 0; }

    .iwcc-buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center; }

    .iwcc-sitelinks {
        text-align: right;
        padding-right: 1.5em;
        a {
            margin: .5em 0 .5em 1em; } }


    .iwcc-save-selection,
    .iwcc-accept-all {
        display: inline-block;
        margin: 0 0 0 .5em; } }


@media (min-width: 50em) {
    .iwcc-wrapper-inner {
        padding: 2em; }

    .iwcc-buttons-sitelinks {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse; }

    .iwcc-close-box {
        font-size: 2em;
        margin: 0; } }


