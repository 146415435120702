* {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
i {
  font-style: italic; }

html, body {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  font-size: 18px !important;
  color: $type-darkgrey;
  word-break: break-word; }

a {
  color: $mediumgrey;
  font-weight: 400;

  &:hover,
  &:active {
    color: $mediumgrey; } }

p {
  margin: 1em 0; }

p, a, label, blockquote {
  line-height: 1.6; }

h4 {
  color: $type-darkgrey;
  font-weight: 200;
  fonst-size: rem(20px); }
