.cta-footer {
  @include align-center;
  flex-direction: column;
  background-color: $hero-bg;
  background-color: var(--main-color);
  padding: 2vh 20px;
  @include tablet {
    padding: 2vh 40px; }

  &__headline {
    text-align: center;
    color: #ffffff;
    font-size: rem(32px);
    font-weight: 400;
    margin: 0;
    @include tablet {
      font-size: rem(38px); } }

  &__subheadline {
    text-align: center;
    color: #ffffff;
    font-size: rem(22px);
    font-weight: 200;
    margin: 20px 0 30px 0;
    @include tablet {
      font-size: rem(32px);
      margin: 10px 0 40px 0; } } }
